<template>
  <div>
      <LayoutHead />
      <main>
        <BasicBtErrorBoundary>
          <slot />
        </BasicBtErrorBoundary>
      </main>
      <LayoutFooter />
  </div>
</template>
<script setup lang="ts">
</script>